import "./App.css";
import { animateScroll as scroll } from 'react-scroll';
import React, {useState, useEffect} from 'react';
import { isVisible } from '@testing-library/user-event/dist/utils';

class Intro extends React.Component {
    scrollToTop = () => {
        scroll.scrollToTop();
    };
    BackToTopButton = () => {
        const [isVisible , setIsVisible] = useState(false);
    
    const handleScroll = () => {
        const scrollTop =  window.scrollY || document.documentElement.scrollTop;
        setIsVisible(scrollTop > window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return()=>window.removeEventListener('scroll', handleScroll);
    },[]);
}
    render(){
    return ( 
        <div className="intro">
            <a href='/'><button className="back"><i class="uil uil-arrow-left"></i>Back to homepage</button></a>
            <button className={`rob ${ isVisible ? 'visible': ''}`} onClick={this.scrollToTop}><i class="uil uil-arrow-up"/></button>

            <div className="intro-top">
                <p>Pishon Hydrocarbon is an integrated engineering and contracting Services
Company operating in Nigeria. We are in the business of delivering client-specific
solutions for projects related services. The integrated package of services
encompasses engineering, procurement, construction, commissioning and
maintenance.
We are renowned for quality, safety and on-time delivery of projects and products.
We deliver turnkey projects to our Customers to their entire technical
specifications. This unique strength enables us to provide solutions as an EPCC
(Engineering, Procurement, Construction and Commissioning) services provider to
a host of our valued clients.
Pishon has executed numerous projects in the sectors of Oil and Gas industry.
These projects have been delivered to clients who are investors, operators or
themselves EPC contractors. Exploration and production companies, oil marketing
companies and owners/operators have had their investments secured by
entrusting Pishon to implement their projects according to their budgets and time
schedules while adhering to international quality standards and with zero
tolerance towards unsafe practices and damage to the environment. We are proud of our heritage of safely implementing projects
which have contributed significantly to the local economies. The
combination of an experienced human resource pool and well
established project management systems have enabled us to
succeed and grow in a competitive environment. We believe in
Quality and not in Quantity. Client satisfaction is our prime
objective and the reason to our success.</p>
            </div>
            <div className="intro-images">
                <h1>PHOTO GALLERY</h1>
                <img alt='' src={require("./Images/pish10.jpg")}/>
                <img alt='' src={require("./Images/pish7.jpg")}/>
                <img alt='' src={require("./Images/pish12.jpg")}/>
                <img alt='' src={require("./Images/pish14.jpg")}/>
                <img alt='' src={require("./Images/pish36.jpg")}/>
                <img alt='' src={require("./Images/pish43.jpg")}/>
                <img alt='' src={require("./Images/port2.png")}/>
                <img alt='' src={require("./Images/pish37.jpg")}/>
                <img alt='' src={require("./Images/pish35.jpg")}/>
                <img alt='' src={require("./Images/pish39.jpg")}/>
                <img alt='' src={require("./Images/pish42.jpg")}/>
                <img alt='' src={require("./Images/pish12.jpg")}/>
                <img alt='' src={require("./Images/pish3.jpg")}/>
                <img alt='' className='to' src={require("./Images/pish38.jpg")}/>
                <img alt='' className='to' src={require("./Images/pish5.jpg")}/>
                <img alt='' className='to' src={require("./Images/pish6.jpg")}/>
                <img alt='' className='to' src={require("./Images/lol.jpg")}/>
                <img alt='' className='to' src={require("./Images/pish13.jpg")}/>
                <img alt='' className='to' src={require("./Images/pish18.jpg")}/>
                <img alt='' className='to' src={require("./Images/pish17.jpg")}/>
                
            </div>
            <div id='section5' className='footer' >
                <div className="footer1">
                    <h1>CONTACT US</h1>
                    <div className='ross'> 
                        <p className='mon'>10 TECON ENERGY ROAD, TRANS AMADI
                            INDUSTRIAL LAYOUT,<br/> PORT HARCOURT, RIVERS STATE,
                            NIGERIA.</p>
                    <p className='joe'>EMAIL:   pishonhydrocarbon@gmail.com <br/> PHONE:   +2349070767832</p>
                    </div>
                    <div className='chand'>
                    <a href='https://web.facebook.com/pishonhydrocarbon?_rdc=1&_rdr' rel="noreferrer" target='_blank' ><i class="uil uil-facebook-f"></i></a>
                    <a href='https://www.instagram.com/pishon_hydrocarbon/' rel="noreferrer" target='_blank'><i class="uil uil-instagram"></i></a>
                    <a href='https://www.linkedin.com/in/pishon-hydrocarbon-9a11b4281/' rel="noreferrer" target='_blank'><i class="uil uil-linkedin-alt"></i> </a>                      
                    </div>
                    <div className="ted">
                <div className="barn">
                    <img alt='' src={require('./Images/logo2.jpg')}/>
                    <p>© Copyright 2023 Pishon Hydrocarbon Limited. All Rights Reserved.</p>
                </div>
            </div>

                </div>
                </div>

        </div>
     );
}
}
export default Intro;