import './App.css';
import React, {useState, useEffect} from 'react';
import Header from './Header';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import { isVisible } from '@testing-library/user-event/dist/utils';

class Home extends React.Component{
    scrollToTop = () => {
        scroll.scrollToTop();
    };
    BackToTopButton = () => {
        const [isVisible , setIsVisible] = useState(false);
    
    const handleScroll = () => {
        const scrollTop =  window.scrollY || document.documentElement.scrollTop;
        setIsVisible(scrollTop > window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return()=>window.removeEventListener('scroll', handleScroll);
    },[]);
}
 render() {
    return ( 
    <div className='home'>
        <div className='landing'>
            <Header/>
            <div className='land-cont'>
                <h1>Welcome to Pishon Hydrocarbon</h1>
                <p>Pishon Hydrocarbon is an integrated engineering and contracting Services Company operating in Nigeria.</p>
            <div>
            <Link to ='/intro'><button className='button1'>Learn more <i class="uil uil-arrow-right"></i></button></Link>
            </div>
            </div>
        </div>
        
        <div className='blue'>
        <p>"We are renowned for quality, safety and on-time delivery of projects and products.
We deliver turnkey projects to our Customers to their entire technical
specifications."
</p>
<button className={`rob ${ isVisible ? 'visible': ''}`} onClick={this.scrollToTop}><i class="uil uil-arrow-up"/></button>
        </div>

        <div id='section1' className='about-sec'>
        <h2 className='about-head'>ABOUT US</h2>
        <div className='about'>
            <div className='about1'>
                <p>PISHON HYDROCARBON LIMITED is a wholly indigenous company that was incorporated in Nigeria to meet the 
                    quest of Nigerian Government in the area of using cutting edge technology to provide solutions to 
                    the Oil and Gas, Agricultural and Industrial sector instead of the importation of finished products.
                    Our corporate headquarters is at 11 Tecon Energy Road, Oginigba/Elekahia Link Road,
                    Trans-Amadi Industrial Layout, Port Harcourt, River State with Liaison offices in Lagos and Warri.
                    Pishon Hydrocarbon Limited was incorporated in 2020 to the Oil and Gas sector in Nigeria. 
                    Some of the companies who have enjoyed our enviable services include but not limited to NPDC, OML 26 AMT and Belema Oil.
                    <br/> <Link to ='/intro'><button className='button2'>Learn more <i class="uil uil-arrow-right"></i></button></Link>
                </p>
                    
            </div>
            <div className="about2"> 
            </div>
        </div>
        <div className="misvis">
            <div className="mission">
                <div clssName='misp'>
                    <div className='mis-img'>
                    <div className='mis-text'>
                        <h2>OUR MISSION</h2>
                        <p>To partner with reputable Research Development
                            centers in Africa and beyond for innovation of Oil
                            & Gas, Agriculture and Industrial Equipment.</p>
                    </div>
                    </div>
                </div>
            </div>
            <div className="vision">
                <div clssName='visp'>
                    <div className='vis-img'>
                    <div className='vis-text'>
                        <h2>OUR VISION</h2>
                        <p>To be the best EPC Company that provides Solutions to the Oil & Gas, Agriculture and Industrial Sectors on the Africa continent using cutting-edge technology</p>
                    </div>
                    </div>
                </div>
            </div>
            
        </div>
        </div>
        <div id='section2' className='services'>
            <h1>OUR SERVICES</h1>
            <div className='serv-info'>
                <div className='serv-details'>
                    <img alt='' className='cole' src={require("./Images/pish24.jpg")}/>
                    <div className='con'>
                        <h3>ENGINEERING</h3>
                        <p>Our Engineering Section is at the forefront of innovation, employing a team of skilled engineers who are passionate about tackling complex challenges and delivering practical solutions. We combine our deep understanding of the industry with the latest technological advancements to develop products that enhance operational efficiency, safety, and sustainability.</p>
                    </div>
                </div>
                <div className='serv-details'>
                <img alt='' className='cole' src={require("./Images/pish29.jpg")}/>
                    <div className='con'>
                        <h3>EQUIPMENT DESIGN AND FABRICATION</h3>
                        <p> With our vast experience and expertise in this sector, we deliver high-quality, customized equipment that meets the unique demands and challenges of the industry.We collaborate closely with our clients to understand their specific requirements and deliver equipment that optimizes performance, efficiency, and safety.</p>
                    </div>
                </div>
                <div className='serv-details'>
                <img alt='' className='cole' src={require("./Images/pish12.jpg")}/>
                    <div className='con'>
                        <h3>HAZID/HAZOP</h3>
                        <p>    We understand the critical importance of identifying and mitigating potential hazards in the oil and gas industry. Our HAZID (Hazard Identification) and HAZOP (Hazard and Operability Study) Services are designed to assess the risks associated with your operations and facilities, enabling you to make informed decisions and implement effective safety measures.                    </p>
                    </div>
                </div>
                <div className='serv-details'>
                <img alt='' className='cole' src={require("./Images/pish21.jpg")}/>
                    <div className='con'>
                        <h3>INSTALLATION SERVICE</h3>
                        <p>We understand that proper installation is crucial for the optimal performance and reliability of equipment in the oil and gas industry. Our Installation Services are designed to ensure seamless integration, safe operation, and maximum efficiency of the equipment we deliver.</p>
                    </div>
                </div>
                <div className='serv-details'>
                <img alt='' className='cole' src={require("./Images/pish37.jpg")}/>
                    <div className='con'>
                        <h3>COMMISSIONING</h3>
                        <p>We understand that the successful commissioning of equipment is vital for the efficient and reliable operation of oil and gas facilities. Our Commissioning Services are designed to ensure that your equipment is thoroughly tested, calibrated, and ready to perform optimally in the field.</p>
                    </div>
                </div>
            </div>
            </div>
            <div id='section3' className='portfolio'>
                <h1>OUR PORTFOLIO</h1>
                <p className='thor'>We have successfully brought unique solutions that solved the unique challenges for
                    our clients. Some of the notable projects includes:</p>
                <div className='port'>
                    <div className='happy'>
                        <img alt='' className='port1' src={require("./Images/port1.png")}/>
                        <p className='loki'>Design, Construction/Fabrication and Installation/Commissioning of Dual pot Sand Trap for OML
26 AMT ( Oginin field) for Nigerian Petroleum Deveelopment Company (NPDC) OML 26 AMT.</p>
                    </div>
                    <div className='happy2'>
                        <img alt='' className='port2' src={require("./Images/lol.jpg")}/>
                        <p className='loki2'>Design, Construction/Fabrication and Installation/Commissioning of 2-PHASE GAS SCRUBER FOR
for BELEMA PRODUCING LTD</p>
                    </div>
                    <div className='happy3'>
                        <img alt='' className='port3' src={require("./Images/port3.png")}/>
                        <p className='loki3'>Design, Construction/Fabrication and Installation/Commissioning Sand Trap for Belema oil at
Idama Well 7</p>
                    </div>
                    <div className='happy4'>
                        <img alt='' className='port4' src={require("./Images/pish24.jpg")}/>
                        <p className='loki4'>Design, fabrication of 1440psi 3" Gas and oil manifolds for major projects
</p>
                    </div>
                    <div className='happy5'>
                        <img alt='' className='port5' src={require("./Images/pish18.jpg")}/>
                        <p className='loki5'>Design and fabrication of Effluent water treatment unit for water purification</p>
                        </div></div>
                </div>
            
            <div id='section5' className='footer' >
                <div className="footer1">
                    <h1>CONTACT US</h1>
                    <div className='ross'> 
                        <p className='mon'>10 TECON ENERGY ROAD, TRANS AMADI
                            INDUSTRIAL LAYOUT,<br/> PORT HARCOURT, RIVERS STATE,
                            NIGERIA.</p>
                    <p className='joe'>EMAIL:   pishonhydrocarbon@gmail.com <br/> PHONE:   +2349070767832</p>
                    </div>
                    <div className='chand'>
                    <a href='https://web.facebook.com/pishonhydrocarbon?_rdc=1&_rdr' rel="noreferrer" target='_blank' ><i class="uil uil-facebook-f"></i></a>
                    <a href='https://www.instagram.com/pishon_hydrocarbon/' rel="noreferrer" target='_blank'><i class="uil uil-instagram"></i></a>
                    <a href='https://www.linkedin.com/in/pishon-hydrocarbon-9a11b4281/' rel="noreferrer" target='_blank'><i class="uil uil-linkedin-alt"></i> </a>                      
                    </div>
                </div>
                <div className="ted">
                <div className="barn">
                    <img alt='' src={require('./Images/logo2.jpg')}/>
                    <p>© Copyright 2023 Pishon Hydrocarbon Limited. All Rights Reserved.</p>
                </div>
            </div>
            </div>
            </div>
        
     );
}
} 
export default Home;
