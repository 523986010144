import './App.css';
import Home from './Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Intro from './Intro';

function App() {
  return (
    <Router>
      <Switch>
      <Route exact path='/' component={Home}>
      
      </Route>
      <Route path="/intro" component={Intro}>
      </Route>
      </Switch>
    </Router>
  );
}

export default App;
