import React, { useState} from "react";
const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
    return ( 
        <div className='header'>
            <div className='left-top'>
                <img className='icon' src={require("./Images/Logo.png")} alt="" />
            </div>
            <button className="header-toggle" onClick={toggleMenu}>
            {isMenuOpen ? <i class="uil uil-times"></i> : <i class="uil uil-bars"></i>}
            </button>
            <div  className={`right-top ${isMenuOpen ? 'active' : ''}`}>
           
                <h2><a href='#section1'><i class="uil uil-users-alt"></i>ABOUT US</a></h2>
                <h2><a href='#section2'><i class="uil uil-setting"></i>OUR SERVICES</a></h2>
                <h2><a href='#section3'><i class="uil uil-suitcase"></i>PORTFOLIO</a></h2>
                <h2><a href='#section5'><i class="uil uil-phone"></i>CONTACT US</a></h2>
            </div>
        </div>        
     );
}
 
export default Header;
